import React, {ReactNode, useEffect, useState} from "react";
import {
    GoogleMap,
    GroundOverlay,
    InfoBox,
    InfoWindow,
    OverlayView,
    Polygon, useGoogleMap,
    useJsApiLoader
} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {getGipotenuza} from "../Libs/CalculateLeyoutPositions";


export interface ILayoutPictureOverlayProps {
    layout: any,
    markers: any,
    busMarkers?: any,
    overlayBounds?: google.maps.LatLngBounds,
    onLayoutClick?: () => void,
}


export default function LayoutPictureOverlay(props: ILayoutPictureOverlayProps) {
    const onClick = () => {
        console.log('click on marker')
//        if (props.afterClick) props.afterClick()
    }

    const map = useGoogleMap()

    // const [overlayBounds, setOverlayBounds] = useState(new google.maps.LatLngBounds())

    const [styleDiv, setStyleDiv] = useState({
        borderStyle: 'solid',
        borderWidth: '0px', // 2px
        borderColor: 'cyan',
        position: 'absolute',
//        transformOrigin: '100% 100%',
//        transform: 'rotate(0deg)',
    } as any)

    const [styleImg, setStyleImg] = useState({
//    const  = {
        //     display: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        opacity: 1, // 0.4
//        transform: 'rotate(50deg)',
       //  filter: 'alpha(opacity=100)',
//        transform: 'translateY(-100%)',
    }  as any)//


    const calcPosition = () => {
        if (!map) return

        setTimeout(()=>{
            const overlayBounds = props.markers.overlayBounds
            const projection = map.getProjection()
            if (projection == null) return
            const swPoint = overlayBounds.getSouthWest()
            const sw = projection.fromLatLngToPoint(swPoint)
            if (sw == null) return
            const nePoint = overlayBounds.getNorthEast()
            const ne = projection.fromLatLngToPoint(nePoint)
            if (ne == null) return
            var min_x = Math.min(sw.x, ne.x)
            var max_x = Math.max(sw.x, ne.x)
            var min_y = Math.min(sw.y, ne.y)
            var max_y = Math.max(sw.y, ne.y)

            let dx = (max_x - min_x)
            let dy = (max_y - min_y)
            console.log('dx / dy : ' + dx + '/' + dy )

            // let geoPixelData = {
            //     mapWidth: dx,
            //     mapHeight: dy,
            //     mapLngLeft: swPoint.lng(),
            //     mapLngRight: nePoint.lng(),
            //     mapLatBottom: swPoint.lat(),
            // }
            // console.log('LPO geoPixelData', geoPixelData)

            console.log('props.markers.lbMarker_: ', props.markers.lbMarker_)
            const y = projection.fromLatLngToPoint(new google.maps.LatLng(
                props.markers.posY.lat,
                props.markers.posY.lng,
            ));
            if (y == null) return

            // move point of rotation Vertical
//            min_y = Math.min(sw.y, y.y)
//            max_y = Math.max(sw.y, y.y)

            let ww = Math.max(sw.y, y.y) - Math.min(sw.y, y.y) // max_y - min_y //Math.sqrt((lb.y - sw.y)*(lb.y - sw.y) + (lb.x - sw.x)*(lb.x - sw.x))
            console.log('dy, ww, div: ', dy, ww, (ww/dy))
            let ff = ww/dy * 100
            console.log('ff: ', ff)
            ff = 100 - ff
            console.log('ff: ', ff)

            // move point of rotation Horizontal
            const x = projection.fromLatLngToPoint(new google.maps.LatLng(
                props.markers.posY.lat,
                props.markers.posY.lng,
            ));
            if (x == null) return

            // min_y = Math.min(sw.x, x.x)
            // max_y = Math.max(sw.x, x.x)

            let hww = Math.max(sw.x, x.x) - Math.min(sw.x, x.x)  // max_y - min_y //Math.sqrt((lb.y - sw.y)*(lb.y - sw.y) + (lb.x - sw.x)*(lb.x - sw.x))
            console.log('dx, hww, div: ', dx, hww, (hww/dx))
            let gg = hww/dx * 100
            console.log('gg: ', gg)

            if (props.markers.angle < 0) {
    //            gg = 0
            } else {
                console.log('set ff zero')
                //ff = 0
            }

            // width
            let _w = props.markers.width_full / dx * 100
            console.log('dx, lay_x, div: ', dx, (props.markers.width_full), _w)

            // height
            let _h = props.markers.height_full / dy * 100
            console.log('dy, lay_y, div: ', dy, (props.markers.height_full), _h)


            setStyleDiv({
                ...styleDiv,
                height: '100%',
                width: '100%',
                left: 0,
                bottom: 0,
            })

//            ff = 44
            setStyleImg({
                ...styleImg,

//                transformOrigin: 'top left',
                transformOrigin: 'top left',
                //bottom: 0,
                left: gg + '%', //0,
                top: ff + '%', //'40%',

                height: _h + '%', //'80.675765844%',
                width: _w + '%', //'190%', // _w + '%', //
                position: 'absolute',
//                transformOrigin: 'bottom left',
//                transformOrigin: '100% 100%',
//                transform: 'rotate(90deg)',
                transform: 'rotate(' + props.markers.angle +'deg)',
                opacity: 0.4,
            })
            // calculatePostions(map)

        }, 300)

    //     setTimeout(()=>{
    //
    //         const data = props.markers
    //         console.log('get all dimensions', data)
    //         const sw = {
    //             x: Math.min(data.pLB.x, data.pX.x, data.pY.x, data.pXY.x),
    //             y: Math.min(data.pLB.y, data.pX.y, data.pY.y, data.pXY.y),
    //         }
    //         const ne = {
    //             x: Math.max(data.pLB.x, data.pX.x, data.pY.x, data.pXY.x),
    //             y: Math.max(data.pLB.y, data.pX.y, data.pY.y, data.pXY.y),
    //         }
    //         const rect = {
    //             x: ne.x - sw.x,
    //             y: ne.y - sw.y,
    //         }
    //         console.log('dimensions sw', sw)
    //         console.log('dimensions ne', ne)
    //         console.log('dimensions', rect)
    //
    //         // const a1 = Math.max(data.pX.x, data.pLB.x) - Math.min(data.pX.x, data.pLB.x)
    //         // const b1 = Math.max(data.pX.y, data.pLB.y) - Math.min(data.pX.y, data.pLB.y)
    //         // const layoutWidth = Math.sqrt(a1*a1 + b1*b1)
    //         //
    //         // console.log('layoutWidth', layoutWidth)
    //         // console.log('layoutWidth a1', a1)
    //         // console.log('layoutWidth b1', b1)
    //
    //         console.log('___ pLB', data.pLB)
    //         console.log('___ pX', data.pX)
    //         console.log('___ pY', data.pY)
    //         console.log('___ pXY', data.pXY)
    //         const layoutWidth = getGipotenuza(data.pX, data.pLB) // w
    //
    //
    //         // my point to test...
    //         /*
    //         device: "4214"
    //         ​​lat: "52.055612"
    //         ​lng: "5.105936"
    //          */
    //         // const device_id = 4214
    //         // const p = new google.maps.LatLng({
    //         //     lat: 52.055612,
    //         //     lng: 5.105936,
    //         // })
    //
    //         console.log('my buses', props.busMarkers)
    //         /*
    //         device: "4203"
    //         ​lat: 52.055711
    //         lng: 5.105576
    //          */
    //         // const p = new google.maps.LatLng({
    //         //     lat: 52.055711,
    //         //     lng: 5.105576,
    //         // })
    //         /*
    //         ​device: "4213"
    //         lat: "52.055653"
    //         ​lng: "5.1055"
    //          */
    //         // const device_id = 4213
    //         // const p = new google.maps.LatLng({
    //         //     lat: 52.055653,
    //         //     lng: 5.1055,
    //         // })
    //         /*
    //         ​  device: "4204"
    //           lat: "52.055797"
    //           ​​lng: "5.105231"
    //          */
    //         // const p = new google.maps.LatLng({
    //         //     lat: 52.055797,
    //         //     lng: 5.105231,
    //         // })
    //         /*
    //         device: "4212"
    //         ​​lat: "52.055566"
    //         ​​lng: "5.10554"
    //          */
    //         // const device_id = 4212
    //         // const p = new google.maps.LatLng({
    //         //     lat: 52.055566,
    //         //     lng: 5.10554,
    //         // })
    //
    //         const projection = map?.getProjection()
    //
    //         props.busMarkers.map((bus: any)=>{
    //
    //             console.log('bus', bus)
    //
    //             const p = new google.maps.LatLng({
    //                 lat: bus.lat,
    //                 lng: bus.lng,
    //             })
    //
    //             const testPoint = projection?.fromLatLngToPoint(p)
    //             console.log('testPoint', testPoint)
    //
    //             const layoutB = getGipotenuza(data.pLB, testPoint) // b
    //             const layoutC = getGipotenuza(data.pX, testPoint) // c
    //
    //             // find angle...
    //             const cos_alpha =  (layoutB*layoutB + layoutWidth*layoutWidth - layoutC*layoutC) / (2*layoutB*layoutWidth)
    //             console.log('testPoint cos_alpha', cos_alpha)
    //             const alpha = Math.acos(cos_alpha) * 180 / Math.PI
    //             console.log('testPoint alpha', alpha)
    //
    //             const proportion = layoutB / layoutWidth
    //             console.log('testPoint proportion', proportion)
    //
    //             const device_id = bus.id
    //             const key = "test_" + device_id
    //             console.log('key: ' + key)
    //             localStorage.setItem(key, JSON.stringify({
    //                 angle: alpha,
    //                 proportion: proportion,
    //             }))
    //
    //             return true
    //         })
    //
    //
    //     }, 500)
    }


//     useEffect(()=>{
//         calcPosition()
// //        map.getCenter()
//     }, [overlayBounds])

    useEffect(()=>{
        calcPosition()
//        map.getCenter()
        if (map) {
            map.fitBounds(props.markers.overlayBounds, 100)
        }
    }, [props.markers, props.busMarkers])

    useEffect(()=>{
        // console.log("set bounds ")
        // let bounds = new google.maps.LatLngBounds()
        //
        //
        // const LB = { lat: props.markers.lbMarker_.lat, lng: props.markers.lbMarker_.lng }
        // const TR = { lat: props.markers.lbMarker_.lat, lng: props.markers.lbMarker_.lng }
        //
        // LB.lat = Math.min(props.markers.lbMarker_.lat,
        //     props.markers.posX.lat,
        //     props.markers.posY.lat,
        //     props.markers.posXY.lat
        //     )
        // LB.lng = Math.min(props.markers.lbMarker_.lng,
        //     props.markers.posX.lng,
        //     props.markers.posY.lng,
        //     props.markers.posXY.lng
        // )
        // TR.lat = Math.max(props.markers.lbMarker_.lat,
        //     props.markers.posX.lat,
        //     props.markers.posY.lat,
        //     props.markers.posXY.lat
        // )
        // TR.lng = Math.max(props.markers.lbMarker_.lng,
        //     props.markers.posX.lng,
        //     props.markers.posY.lng,
        //     props.markers.posXY.lng
        // )
        //
        //
        //  bounds.extend({ lat: LB.lat, lng: LB.lng })
        //  bounds.extend({ lat: TR.lat, lng: TR.lng })
        // setOverlayBounds(bounds)

    }, [map, props.markers])


    return (
        <>
        <OverlayView
            bounds={props.markers.overlayBounds}
            mapPaneName={OverlayView.OVERLAY_LAYER}
        >
            <div style={styleDiv} onClick={props.onLayoutClick}>
            <img
                style={styleImg}
                src={props.layout.layout_photo}
            />
            </div>


        </OverlayView>


    </>
    )
}

/*
            <Marker
                position={new google.maps.LatLng(props.layout.lb.lat, props.layout.lb.lon)}
                icon={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                // onClick={mClick}
                title={'lb'}
            />
            <Marker
                position={new google.maps.LatLng(props.layout.lt.lat, props.layout.lt.lon)}
                icon={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                // onClick={mClick}
                title={'lt'}
            />
            <Marker
                position={new google.maps.LatLng(props.markers.posX.lat, props.markers.posX.lng)}
                icon={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                // onClick={mClick}
                title={'poxX'}
            />
            <Marker
                position={new google.maps.LatLng(props.markers.posY.lat, props.markers.posY.lng)}
                icon={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                // onClick={mClick}
                title={'posY'}
            />
            <Marker
                position={new google.maps.LatLng(props.markers.posXY.lat, props.markers.posXY.lng)}
                icon={'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
                // onClick={mClick}
                title={'posXY'}
            />

 */
