import React, {ReactNode, useEffect, useState} from "react";
import {GoogleMap, InfoBox, InfoWindow, useJsApiLoader} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";


export interface IGoogleMapLoaderProps {
    isOpen?: boolean,
    hideMenu?: boolean,
    afterClick?: () => void,
    children?: any,
}
//
// interface IAnyReactComponentProps {
//     key?: number,
//     text: string,
//     position: google.maps.LatLng,
//     onClick?: () => void,
// }
//
// interface IMarkerProps {
//     id: number,
//     text: string,
//     lat: number,
//     lng: number,
//     icon?: any,
// }

const libraries = ["drawing", "geometry", "localContext", "places", "visualization"] as Libraries

// const defaultMarkers: IMarkerProps[] = []
//
// const AnyReactComponent = (props: IAnyReactComponentProps) => <div onClick={props.onClick} className="markerDot">{props.text}!</div>;

export default function GoogleMapLoader(props: IGoogleMapLoaderProps) {

    // const [myMarkers, setMyMarkers] = useState(defaultMarkers)

    // const [activeMarker, setActiveMarker] = useState(null);

    // const handleActiveMarker = (marker: any) => {
    //     if (marker === activeMarker) {
    //         return;
    //     }
    //     setActiveMarker(marker);
    // };

    // const onClick = () => {
    //     console.log('click on marker')
    //     if (props.afterClick) props.afterClick()
    // }

    // const containerStyle = {
    //     width: '100%',
    //     height: '100%'
    // };
    //
    // const center = {
    //     // lat: 52.473380,
    //     // lng: 13.456899,
    //     lat: 52.05601,
    //     lng: 5.106109,
    // }

    // useEffect(() => {
    //     setMyMarkers([
    //         {
    //             id: 1,
    //             text: "4102",
    //             lat: 52.05601,
    //             lng: 5.106109,
    //             // icon: {
    //             //     path: google.maps.SymbolPath.CIRCLE,
    //             //     scale: 7,
    //             // },
    //         },
    //         {
    //             id: 2,
    //             text: "4103",
    //             lat: 52.055586,
    //             lng: 5.105835,
    //             icon: {
    //                 path:
    //                     "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
    //                 fillColor: "yellow",
    //                 fillOpacity: 0.9,
    //                 scale: 2,
    //                 strokeColor: "gold",
    //                 strokeWeight: 2,
    //             }
    //         },
    //         {
    //             id: 3,
    //             text: "4104",
    //             lat: 52.055735,
    //             lng: 5.105577,
    //         },
    //     ] as IMarkerProps[])
    // }, [])


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
        libraries: libraries,
        // preventGoogleFontsLoading: true,  <- broke InfoWindow
    })

    // const [map, setMap] = React.useState(null)

    // const fitBounds = (map: any) => {
    //     if (map != null && myMarkers != null) {
    //         let bounds = new google.maps.LatLngBounds(center)
    //         // myMarkers.forEach((value) => {
    //         //     bounds.extend({lat: value.lat, lng: value.lng})
    //         // })
    //         for (let i=0;i<myMarkers.length;i++) {
    //             const value = myMarkers[i]
    //             console.log('item: ', value)
    //             bounds.extend({lat: value.lat, lng: value.lng})
    //         }
    //         (map as any).fitBounds(bounds);
    //     }
    //
    // }

    // const onLoad = React.useCallback(function callback(map) {
    // //    const bounds = new window.google.maps.LatLngBounds(center);
    //     // if (myMarkers != null) {
    //     //     myMarkers.forEach((value) => {
    //     //         console.log('item: ', value)
    //     //         bounds.extend({lat: value.lat, lng: value.lng} as unknown as google.maps.LatLng)
    //     //     })
    //     // }
    //   //  map.fitBounds(bounds);
    //  //   fitBounds(map)
    //  //    console.log('onLoad: ', myMarkers)
    //     setMap(map)
    // }, [])

    // const onUnmount = React.useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    // const onMarkerLoad = (marker: any) => {
    //     console.log('marker: ', marker)
    // }
    //
    // const optionsInfoBox = { closeBoxURL: '', enableEventPropagation: true };
    //
    // const onLoadInfoBox = (infoBox: any) => {
    //     console.log('infoBox: ', infoBox)
    // };
    //
    // const onLoadInfoWindow = (infoWindow: google.maps.InfoWindow) => {
    //     console.log('infoWindow: ', infoWindow)
    // }


    return isLoaded ? (
        <div style={{ height: '100vh', width: '100%' }}>
            {props.children}
        </div>
    ) : <></>
}
