import React, {ReactNode, useEffect, useState} from "react";
import {GoogleMap, InfoBox, InfoWindow, useJsApiLoader} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import globals from "../globals";
import {useRecoilState} from "recoil";
import {userLoggedIn} from "../atoms";
import {string} from "prop-types";
import {calculateLayoutPostions} from "../Libs/CalculateLeyoutPositions";
import LayoutPictureOverlay from "./LayoutPictureOverlay";
import config from "../config";


export interface ILiveMapViewProps {
    search: string,
    isOpen?: boolean,
    hideMenu?: boolean,
    afterClick?: () => void,
}

interface IAnyReactComponentProps {
    key?: number,
    text: string,
    position: google.maps.LatLng,
    onClick?: () => void,
}

interface IMarkerProps {
    id: number,
    text: string,
    title?: string,
    lat: number,
    lng: number,
    icon?: any,
    info?: boolean,
}

const defaultMarkers: IMarkerProps[] = []

const AnyReactComponent = (props: IAnyReactComponentProps) => <div onClick={props.onClick} className="markerDot">{props.text}!</div>;

export default function BrakeshoesLiveMapView(props: ILiveMapViewProps) {

    const [user] = useRecoilState(userLoggedIn);

    const [myMarkers, setMyMarkers] = useState(defaultMarkers)
    const [intervalTime, setIntervalTime] = useState(0 as number)

    const [firstTime, setFirstTime] = useState(true as boolean)
    const [fixedBound, setFixedBound] = useState(null as any)

    const [activeMarker, setActiveMarker] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    // const center = {
    //     // lat: 52.473380,
    //     // lng: 13.456899,
    //     lat: 52.05601,
    //     lng: 5.106109,
    // }

    // console.log('BarcodesLiveMapView ' + props.search)

    const [timeCounter, setTimeCounter] = useState(999 as number)
    const [requestReady, setRequestReady] = useState(true as boolean)

    const [overlays, setOverlays] = useState([] as any)

    useEffect(()=>{
        const id = setTimeout(()=>{
            // console.log('every 1s')

            // console.log('every 1s props.search ' + props.search )
            if (props.search != '') {
                console.log('every 000s props.search ' + props.search )
                setTimeCounter(timeCounter +1)
            } else
            if (timeCounter >= a-1) {
                let d2 = new Date()
                let dd = d2.toISOString().substr(0, 19)
                const ddd = dd.replace('T', ' ')
                // console.log('run now: ' + ddd)
                setTimeCounter(0)
                runGetBusLocations(ddd)
            } else {
                setTimeCounter(timeCounter +1)
            }
        }, 1000)

        return () => {
            clearInterval(id)
        }
    }, [timeCounter])

    const a = 5

    const runGetBusLocations = (ddd: string) => {
        console.log('date 2: '  + ddd)
        if (requestReady) {
            console.log('date 2 : TRUE')
            setRequestReady(false)
            getCurrentPositions(ddd)
        } else {
            console.log('date 2 : FALSE')
        }
    }


    const getCurrentPositions = (d: string) => {
//        let d = "2022-08-28 14:00:43"
        let formData = {
            when: d,
        } as any;

        let token = ''
        const userData = localStorage.getItem('userLoggedIn');

        if (userData) {
            let data = JSON.parse(userData)
            if (data && data.token) {
                token = data.token
                formData = {
                    auth_key: token,
                }
            }
        }

        globals.postRequest(user.url + config.urls.get_brakeshoe_locations, formData,
            (response: any) => {
                let tabledata = response;
                console.log('tabledata:', tabledata)
                console.log('devices:', tabledata.locations)

                let markers = tabledata.locations
                    .filter((device: any) => {
                        let d1 = Date.parse(d)
                        let d2 = Math.round(device.timestamp)
                        // console.log('d2 ' + device.timestamp)
                        // console.log('d2 ' + d2)
                        // console.log(device.barcode + ': ' + device.timestamp + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
                        return true || ((d1-d2) < 100*24*60*60*1000) // *24
                    })
                    .map((device: any) => {
                            // console.log("timestamp", device.timestamp)
                            // console.log("timestamp", Math.round(device.timestamp))
                            // console.log("timestamp", new Date(Math.round(device.timestamp)))
                            // console.log("timestamp", (new Date(Math.round(device.timestamp))).toLocaleTimeString())
                            // console.log("timestamp", (new Date(Math.round(device.timestamp))).toLocaleString())
                            let d2222 = new Date(device.timestamp)
                            let d22 = (new Date(Math.round(device.timestamp))).toLocaleTimeString()
                            // console.log("timestamp", "3 " + d22)
//                        console.log(device.device + ': ' + device.when )
                        let color = "yellow"
                        let d1 = Date.parse(d)
                        let d2 = Math.round(device.timestamp) //new Date(device.timestamp)
                        if (d1-d2 > 60*60*1000) {
                            // old...
                            color = "red"
                            d22 = (new Date(Math.round(device.timestamp))).toLocaleString()
                            // console.log("timestamp 2", " 2 " +d22)
                        }
                            //console.log(device.device + ': ' + device.when + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
//                            return ((d1-d2) < 24*) // *24
//                         console.log("timestamp", d22)
//                             console.log("timestamp")
                        return {
                            id: device.mac,
                            title: device.mac + "\n" + device.minor, //device.when, //"4102",
                            text: device.mac + "\n" + device.minor + "\n" + d22, //device.when, //"4102",
                            lat: 1*device.lat,
                            lng: 1*device.lng,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 7,
                                fillColor: color,
                                fillOpacity: 0.9,
                                strokeColor: "gold",
                                strokeWeight: 2,
                            },
                            info: true,
                        }
                    }
                )
                setMyMarkers(markers)
                setRequestReady(true)
            },
            (message: any) => {
                alert('error' + message)
                globals.log('Error:', message)
                // setError(message)
                // setIsOpenError(true)
                setRequestReady(true)
            })
    }

    const runSearch = (value: string) => {
        let formData = {
            barcode: value,
            limit: 1,
        } as any;

        globals.postRequest(user.url + config.urls.get_brakeshoe_locations, formData,
            (response: any) => {
                let tabledata = response;
                console.log('get_brakeshoe_locations:', tabledata)

                let markers = tabledata.locations
                    .filter((device: any) => {
                        return device.mac == value || device.minor == value
                    })
                    .map((device: any) => {
                            let d2222 = new Date(Math.round(device.timestamp))
                            let d22 = (new Date(Math.round(device.timestamp))).toLocaleTimeString()
//                        console.log(device.device + ': ' + device.when )
                            let color = "yellow"
                            //console.log(device.device + ': ' + device.when + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
//                            return ((d1-d2) < 24*) // *24

                            let barcode = props.search
                            return {
                                id: device.mac,
                                title: device.mac + " " + device.minor, //device.when, //"4102",
                                text: device.minor + "\n" + (new Date(Math.round(device.timestamp))).toLocaleString(), //device.when, //"4102",
                                lat: 1*device.lat,
                                lng: 1*device.lng,
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 7,
                                    fillColor: color,
                                    fillOpacity: 0.9,
                                    strokeColor: "gold",
                                    strokeWeight: 2,
                                },
                                info: true,
                            }
                        }
                    )
                setFirstTime(true)
                setMyMarkers(markers)
                setRequestReady(true)
            },
            (message: any) => {
                globals.log('Error:', message)
                // setError(message)
                // setIsOpenError(true)
                setRequestReady(true)
            })
    }


    const [map, setMap] = React.useState(null)

    // load maps
    useEffect(()=>{
        if (!map) return
        let formData = {} as any
        globals.postRequest(user.url + config.urls.maps, formData,
            (response: any) => {
                let tabledata = response;
                // console.log('load maps:', tabledata)

                if (tabledata.hasOwnProperty('maps')) {
                    console.log(tabledata.maps)
                    let d = tabledata.maps.map((row: any)=>{
                        let layout = {
                            layout_photo: row.picture,
                        }
                        let markers = calculateLayoutPostions(map as unknown as google.maps.Map, row)
                        return {
                            layout: layout,
                            markers: markers,
                        }
                    })
                    setOverlays(d)
                }
                // setMyMarkers(markers)
                // setRequestReady(true)
            },
            (message: any) => {
                globals.log('Error:', message)
            })
    }, [map])

    const fitBounds = (map: any) => {
        if (map != null && myMarkers != null && myMarkers.length > 0 && firstTime) {
            setFirstTime(false)
            let bounds = new google.maps.LatLngBounds()
            // myMarkers.forEach((value) => {
            //     bounds.extend({lat: value.lat, lng: value.lng})
            // })
            for (let i=0;i<myMarkers.length;i++) {
                const value = myMarkers[i]
                // console.log('item: ', value)
                bounds.extend({lat: value.lat, lng: value.lng})
            }
            (map as any).fitBounds(bounds);
        }
    }

    const onLoad = React.useCallback(function callback(map) {
        // console.log('onLoad: ', myMarkers)
        setMap(map)
        setFirstTime(true)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onMarkerLoad = (marker: any) => {
        // console.log('marker: ', marker)
    }

    useEffect(()=>{
        fitBounds(map)
        if (fixedBound) {
//            (map as any).fitBounds(fixedBound);
        }
    }, [myMarkers])

    useEffect(()=>{
        fitBounds(map)
    }, [map])

    useEffect(()=>{
        if (props.search != '') {
            runSearch(props.search)
        } else {
            setFirstTime(true)
        }
    }, [props.search])

    const onMyClick = (marker: IMarkerProps) => () => {
        console.log('onMyClick marker', marker)

        let points = myMarkers.filter((item) => {
            return (item.id != marker.id)
        })
        marker.info = !marker.info
        points.push(marker)
        setMyMarkers(points)
        // handleActiveMarker(marker.id)
    }

    const onZoomChanged = () => {
        // console.log('set bounds after zoom')
        if (map) {
            const m = map as unknown as google.maps.Map
            const bounds = m.getBounds()
            setFixedBound(bounds)
        }
    }

    /*
                {
                (layoutPicture) && (layoutPictureMarkers) && (
                    <LayoutPictureOverlay layout={layoutPicture}
                                          markers={layoutPictureMarkers}
                                          busMarkers={busMarkers}
                                          overlayBounds={overlayBounds}
                                          onLayoutClick={onLayoutClick}
                    />
                )
            }

     */
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            // center={center}
            // zoom={5}
            zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onZoomChanged}
        >
            {overlays.map((overlayData: any, idx: any) => {
                // console.log('overlayData', overlayData);
 //               return <div key={idx}></div>
                return <LayoutPictureOverlay key={idx} layout={overlayData.layout}
                                      markers={overlayData.markers}
                />
            })}
            {myMarkers
                .filter((marker)=>{
                    const m = map as unknown as google.maps.Map
                    const b = m.getBounds()
                    if (b) {
                        const ne = b.getNorthEast()
                        const sw = b.getSouthWest()
                        if (ne && sw) {
                            return (
                                sw.lat() < marker.lat && marker.lat < ne.lat() &&
                                sw.lng() < marker.lng && marker.lng < ne.lng()
                            )
                        }
                    }
                    return true
                })
                .map((marker) => {
                const pos = {
                    lat: marker.lat,
                    lng: marker.lng,
                }


                const mClick = () => {
                    console.log('item', marker)
                }

                return (<Marker
                    key={marker.id}
                    onLoad={onMarkerLoad}
                    position={pos}
                    icon={marker.icon}
                    // onClick={mClick}
                    onClick={onMyClick(marker)}
                    title={marker.text}
                >
                    {
                        (marker.info) && (
                            <InfoWindow
                                position={pos}
                                onCloseClick={onMyClick(marker)}
                                options={{
                                    disableAutoPan: true
                                }}
                            >
                                <div>{marker.title}</div>
                            </InfoWindow>
                        )
                    }
                </Marker>)

            })}
        </GoogleMap>
    )
}
