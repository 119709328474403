import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn, menuIsVisible, networkLoadingStatus} from "../atoms";
import globals from "../globals";
import DialogOk, {DialogError} from "../Components/DialogOk"
import AppHeader from "../Components/AppHeader";
import AppMenu from "../Components/AppMenu";
import GoogleMapLoader from "../Components/GoogleMapLoader";
import MainGoogleMap from "./MainGoogleMap";
import BarcodesLiveMapView from "../Components/BarcodesLiveMapView";
import AdjustLayoutMapView from "../Components/AdjustLayoutMapView";

type AssignData = {
    material_id: string,
    material_number: string,
    material_1: string,
    material_TrayNo: string,
    material_3: string,

    beacon_container: string,
    container_id: string,
    container_number: string,

    esltag: string,
//    anyString: string,
}

const defaultData: AssignData = {
    material_id: "",
    material_number: "",
    material_1: "",
    material_TrayNo: "",
    material_3: "",

    beacon_container: "",
    container_id: "",
    container_number: "",

    esltag: "",
}

export default function AdjustLayoutGoogleMap(props: any) {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();
    const [user, setUser] = useRecoilState(userLoggedIn);
//    const resetUserState = useResetRecoilState(userLoggedIn);
    const [isMenuVisible, setMenuVisible] = useRecoilState(menuIsVisible)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);

    const [anyString, setAnyString] = useState("");
    const [searchBarcode, setSearchBarcode] = useState("");
    const [searchBarcodeNow, setSearchBarcodeNow] = useState("");

    // const [barcodeContainer, setContainerBarCode] = useState("00123");
    // const [barcodeBeacon, setBeaconBarCode] = useState("00110");
    // const [barcodeEsl, setEslBarCode] = useState("A0A3B8274E5B2");

    const [assignData, setAssignData] = useState(defaultData)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenError, setIsOpenError] = useState(false)
    const [szErrorMessage, setErrorMessage] = useState("")

    const clickReset = () => {
        // setEslBarCode("")
        // setBeaconBarCode("")
        // setContainerBarCode("")
        setAssignData({
            material_id: "",
            material_number: "",
            material_1: "",
            material_TrayNo: "",
            material_3: "",
            beacon_container: "",
            container_id: "",
            container_number: "",
            esltag: "",
        })
        setAnyString("")
    }

    const setError = (data: string) => {
        setErrorMessage(data)
        // audio.play()
    }

    const closeDialog = () => {
        setIsOpen(false)
        clickReset()
    }

    const closeDialogError = () => {
        setIsOpenError(false)
    }

    // const onClick = (e: any) => {
    //     e.stopPropagation()
    //     e.preventDefault()
    //     resetUserState()
    // }

    const onSearchClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        runSearch()
    }

    const runSearch = () => {
        console.log('onSearchClick: ' + searchBarcode)
        setSearchBarcodeNow(searchBarcode)
    }

    const onCancelSearchClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        //alert('search')
        runCancelSearch()
    }

    const runCancelSearch = () => {
        setSearchBarcode('')
        setSearchBarcodeNow('')
    }

    const onTruckLeaveClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        alert('onTruckLeaveClick ')
    }

    const onMenuClick = () => {
        setMenuVisible({
            isOpen: !isMenuVisible.isOpen
        })
    }

    const onSearchEnter = (e: any) => {
        if (e.keyCode === 13) {
            runSearch()
        }
        if (e.keyCode === 27) {
            runCancelSearch()
        }
    }

    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // inputRef?.current?.focus();
    }, [inputRef])

    const [layoutProps, setLayoutProps] = useState({} as any)
    const [lbLat, setLbLat] = useState('50.02432159138137')
    const [lbLon, setLbLon] = useState('20.18365342832969')
    const [dx, setDx] = useState('0')
    const [dy, setDy] = useState('0')
    // const [ltLat, setLtLat] = useState('50.02290163846082')
    // const [ltLon, setLtLon] = useState('20.170349671615824')
    const [width, setWidth] = useState('144000')
    const [height, setHeight] = useState('118000')
    const [angle, setAngle] = useState('261')

    useEffect(()=>{
        let ll = {...layoutProps}
        if (lbLat != '') ll.lbLat = lbLat
        if (lbLon != '') ll.lbLon = lbLon
        if (dx != '') ll.dx = dx
        if (dy != '') ll.dy = dy
        // if (ltLat != '') ll.ltLat = ltLat
        // if (ltLon != '') ll.ltLon = ltLon
        if (height != '') ll.height = height
        if (width != '') ll.width = width
        if (angle != '') ll.angle = angle
        setLayoutProps(ll)
    }, [lbLat, lbLon, height, width, angle, dx, dy])


    if (!user.isLoggedIn) {
        return (
            <Navigate to="/login" replace />
        )
    }


    return (
        <div className="App"
             ref={inputRef}
        >
            <div>
                <AppHeader afterClick={onMenuClick} isOpen={isMenuVisible.isOpen} hideMenu={true}/>
                <div className="main mainwindow">

                    <div className="fullw">
                        <div>
                            <input type="text" value={lbLat}
                                   onChange={e => setLbLat(e.target.value)}
                                   placeholder="lb.lat"/>
                            <input type="text" value={lbLon}
                                   onChange={e => setLbLon(e.target.value)}
                                   placeholder="lb.lon"/>
                        </div>
                        <div>
                            <input type="text" value={dx}
                                   onChange={e => setDx(e.target.value)}
                                   placeholder="dx"/>
                            <input type="text" value={dy}
                                   onChange={e => setDy(e.target.value)}
                                   placeholder="dy"/>
                        </div>
                        <div>
                            <input type="text" value={width}
                                   onChange={e => setWidth(e.target.value)}
                                   placeholder="width"/>
                            <input type="text" value={height}
                                   onChange={e => setHeight(e.target.value)}
                                   placeholder="height"/>
                        </div>
                        <div>
                            <input type="text" value={angle}
                                   onChange={e => setAngle(e.target.value)}
                                   placeholder="angle"/>
                        </div>
                    </div>
                    <GoogleMapLoader>
                        <AdjustLayoutMapView layoutData={layoutProps}/>
                    </GoogleMapLoader>

                    {/*<DepotMap />*/}

                    <p>

                    </p>

                    <DialogOk onClose={closeDialog} isOpen={isOpen} autoCloseMS={2000}>
                        <p>Everything fine...<br/>
                            No Errors</p>
                    </DialogOk>
                    <DialogError onClose={closeDialogError} isOpen={isOpenError} >
                        <p>{szErrorMessage}</p>
                    </DialogError>
                </div>
            </div>
            <AppMenu isOpen={isMenuVisible.isOpen} afterClick={onMenuClick} />
        </div>
    )
}
