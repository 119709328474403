import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.scss';
import {useTranslation} from "react-i18next";
import Login from "./Login/Login";
import NotFound from "./NotFound/NotFound";
import {
    Routes,
    Route,
} from "react-router-dom";
import Router from './router'
import Logout from "./Login/Logout";
import About from "./Main/About";
import HowToUse from "./Main/HowToUse";
import axios from './http_axios';
import {useRecoilState, useSetRecoilState} from "recoil";
import {networkLoadingStatus, onChangeNetworkLoadingStatus, userLoggedIn} from "./atoms";
import {DialogLoading} from "./Components/DialogOk";
import globals from "./globals";
import MainGoogleMap from "./Main/MainGoogleMap";
import BarcodesLiveMapView from "./Components/BarcodesLiveMapView";
import MainTruckViewGoogleMap from "./Main/MainTruckViewGoogleMap";
import AdjustLayoutGoogleMap from "./Main/AdjustLayoutGoogleMap";
import LayoutLogin from "./Layouts/LayoutLogin";
import LayoutMain from "./Layouts/LayoutMain";
import TruckAtParkingLot from "./Main/TruckAtParkingLot";
import BrakeshoeLocationsViewGoogleMap from "./Main/BrakeshoeLocationsViewGoogleMap";
import BrakeshoeLocations from "./Main/BrakeshoeLocations";

function App() {
    const { t, i18n } = useTranslation()
//    console.log(i18n.language)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);
    const [loadingState, setUpdateNetworkLoadingState] = useRecoilState(onChangeNetworkLoadingStatus);
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        globals.log('useEffect Application')
        if (navigator.splashscreen) {
            setTimeout(()=>{
                navigator.splashscreen.hide();
            }, 7000)
        }

    }, [])

    const setLoadingState = (value: boolean) => {
        // globals.log("networkLoadingState: ", networkLoadingState)
        setUpdateNetworkLoadingState(value)
    }

    useEffect(()=>{
        setIsOpen(loadingState)
    }, [loadingState])

    useEffect(()=>{
        axios.interceptors.request.use((config) => {
            // globals.log('config.url: ' + config.url)
            if (config && config.url) {
                // if (config.url.indexOf('apiv3_geo/locations') < 0) {
                //     setLoadingState(true)
                // }
                // MAN
                // if (config.url.indexOf('apiv3_location/get_barcode_locations') < 0
                //     && config.url.indexOf('apiv3_geo/get_barcode_locations') < 0) {
                //     setLoadingState(true)
                // }
                // BRAKESHOE
                if (config.url.indexOf('apiv3_geo/get_brakeshoe_locations') < 0) {
                    setLoadingState(true)
                }
            }
            return config;
        }, (error) => {
            // trigger 'loading=false' event here
            setLoadingState(false)
            // globals.log('axios ERR')
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // trigger 'loading=false' event here
            setLoadingState(false)
            // globals.log('axios DONE')
            return response;
        }, (error) => {
            // trigger 'loading=false' event here
            // globals.log('axios ERRR 2')
            setLoadingState(false)
            return Promise.reject(error);
        });

        return () => {
            // globals.log("axios.interceptors OFF...")
        }
    }, [])

//    <Route path="/login" element={<Login />} />

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<LayoutLogin />}>
                        <Route index element={<Login />} />
                    </Route>
                    <Route path="/" element={<LayoutMain />}>
                        {/*// MAN*/}
                        {/*<Route index element={<MainTruckViewGoogleMap />} />*/}
                        {/*<Route path="lease" element={<TruckAtParkingLot />} />*/}
                        <Route index element={<BrakeshoeLocationsViewGoogleMap />} />
                        <Route path="brakeshoes" element={<BrakeshoeLocations />} />
                    </Route>
                    <Route path="/layout" element={<AdjustLayoutGoogleMap />}>
                    </Route>
                    <Route path="/login" element={<LayoutLogin />}>
                        <Route index element={<Login />} />
                    </Route>
                    <Route path="/logout" element={<Logout />} />

                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Router>
            <DialogLoading isOpen={isOpen} >
                <p>Loading...</p>
            </DialogLoading>
        </>
  );
}

/*
<Route path="/" element={<MainGoogleMap><ReserveReportView/></MainGoogleMap>}>
</Route>
<Route path="/index.html" element={<MainGoogleMap><ReserveReportView/></MainGoogleMap>}>
</Route>
<Route path="/livemap" element={<MainGoogleMap><LiveMapView/></MainGoogleMap>} />

<Route path="/login" element={<Login />} />
<Route path="/logout" element={<Logout />} />

<Route path="/depots" element={<DepotMap />} />
<Route path="/layouts" element={<MainGoogleMap><OverlayReportView/></MainGoogleMap>} />
<Route path="/report" element={<MainGoogleMap><ReserveReportView/></MainGoogleMap>} />

<Route path="/layout/:layoutId" element={<MainLayout><Layout /></MainLayout>} />
*/
export default App;
