import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn, menuIsVisible, networkLoadingStatus} from "../atoms";
import globals from "../globals";
import DialogOk, {DialogError} from "../Components/DialogOk"
import AppHeader from "../Components/AppHeader";
import AppMenu from "../Components/AppMenu";
import GoogleMapLoader from "../Components/GoogleMapLoader";
import MainGoogleMap from "./MainGoogleMap";
import BarcodesLiveMapView from "../Components/BarcodesLiveMapView";
import BrakeshoesLiveMapView from "../Components/BrakeshoesLiveMapView";

type AssignData = {
    material_id: string,
    material_number: string,
    material_1: string,
    material_TrayNo: string,
    material_3: string,

    beacon_container: string,
    container_id: string,
    container_number: string,

    esltag: string,
//    anyString: string,
}

const defaultData: AssignData = {
    material_id: "",
    material_number: "",
    material_1: "",
    material_TrayNo: "",
    material_3: "",

    beacon_container: "",
    container_id: "",
    container_number: "",

    esltag: "",
}

export default function BrakeshoeLocationsViewGoogleMap(props: any) {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();
    const [user, setUser] = useRecoilState(userLoggedIn);
//    const resetUserState = useResetRecoilState(userLoggedIn);
    const [isMenuVisible, setMenuVisible] = useRecoilState(menuIsVisible)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);

    const [anyString, setAnyString] = useState("");
    const [searchBarcode, setSearchBarcode] = useState("");
    const [searchBarcodeNow, setSearchBarcodeNow] = useState("");

    // const [barcodeContainer, setContainerBarCode] = useState("00123");
    // const [barcodeBeacon, setBeaconBarCode] = useState("00110");
    // const [barcodeEsl, setEslBarCode] = useState("A0A3B8274E5B2");

    const [assignData, setAssignData] = useState(defaultData)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenError, setIsOpenError] = useState(false)
    const [szErrorMessage, setErrorMessage] = useState("")

    const clickReset = () => {
        // setEslBarCode("")
        // setBeaconBarCode("")
        // setContainerBarCode("")
        setAssignData({
            material_id: "",
            material_number: "",
            material_1: "",
            material_TrayNo: "",
            material_3: "",
            beacon_container: "",
            container_id: "",
            container_number: "",
            esltag: "",
        })
        setAnyString("")
    }

    const setError = (data: string) => {
        setErrorMessage(data)
        // audio.play()
    }

    const closeDialog = () => {
        setIsOpen(false)
        clickReset()
    }

    const closeDialogError = () => {
        setIsOpenError(false)
    }

    // const onClick = (e: any) => {
    //     e.stopPropagation()
    //     e.preventDefault()
    //     resetUserState()
    // }

    const onSearchClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        runSearch()
    }

    const runSearch = () => {
        console.log('onSearchClick: ' + searchBarcode)
        setSearchBarcodeNow(searchBarcode)
    }

    const onCancelSearchClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        //alert('search')
        runCancelSearch()
    }

    const runCancelSearch = () => {
        setSearchBarcode('')
        setSearchBarcodeNow('')
    }

    const onTruckLeaveClick = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        alert('onTruckLeaveClick ')
    }

    // const onMenuClick = () => {
    //     setMenuVisible({
    //         isOpen: !isMenuVisible.isOpen
    //     })
    // }

    const onSearchEnter = (e: any) => {
        if (e.keyCode === 13) {
            runSearch()
        }
        if (e.keyCode === 27) {
            runCancelSearch()
        }
    }

    // const inputRef = useRef<HTMLDivElement>(null);
    //
    // useEffect(() => {
    //     // inputRef?.current?.focus();
    // }, [inputRef])

    // if (!user.isLoggedIn) {
    //     return (
    //         <Navigate to="/login" replace />
    //     )
    // }

    return (
            <>
                    <div className="fullw">
                        <input type="text" value={searchBarcode}
                               onChange={e => setSearchBarcode(e.target.value)}
                               onKeyUp={onSearchEnter}
                               placeholder="Enter search"/>
                        <button onClick={onSearchClick}>Search</button>
                        <button onClick={onCancelSearchClick}>Cancel Search</button>
                        {
                            (false) && (
                                <button onClick={onTruckLeaveClick}>Truck Leave</button>
                            )
                        }
                    </div>
                    <GoogleMapLoader>
                        <BrakeshoesLiveMapView search={searchBarcodeNow}/>
                    </GoogleMapLoader>

                    <p>

                    </p>

        </>
    )
}
/*
                    <DialogOk onClose={closeDialog} isOpen={isOpen} autoCloseMS={2000}>
                        <p>Everything fine...<br/>
                            No Errors</p>
                    </DialogOk>
                    <DialogError onClose={closeDialogError} isOpen={isOpenError} >
                        <p>{szErrorMessage}</p>
                    </DialogError>

 */