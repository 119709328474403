import React, {FormEvent, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../Images/logo_login.png';
import { useForm, SubmitHandler  } from "react-hook-form";
import {useRecoilState, useRecoilValue} from "recoil";
import {userLoggedIn} from '../atoms'
import {Navigate} from "react-router-dom";
import config from '../config'
import axios from 'axios';
import md5 from 'md5';
import globals from "../globals";
import AppHeader from "../Components/AppHeader";
import { Outlet } from 'react-router-dom'

export default function LayoutLogin() {
    const {t, i18n} = useTranslation()

    return (
        <div className="AppLogin">
            <div className="main loginwindow">
                <div>
                    <div className="logo">
                        <img src={logo} />
                    </div>

                    <Outlet />
                </div>
            </div>
        </div>
    )
}

