import React, {FormEvent, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../Images/logo_login.png';
import { useForm, SubmitHandler  } from "react-hook-form";
import {useRecoilState, useRecoilValue} from "recoil";
import {userLoggedIn} from '../atoms'
import {Navigate} from "react-router-dom";
import config from '../config'
import axios from 'axios';
import md5 from 'md5';
import globals from "../globals";
import AppHeader from "../Components/AppHeader";

type FormLoginValues = {
    username: string;
    password: string;
};

export default function Login() {
    const {t, i18n} = useTranslation()

    const [user, setUser] = useRecoilState(userLoggedIn);

    const [error, setError] = useState("");

    const { register, handleSubmit, watch, formState: { errors } } = useForm<FormLoginValues>({
        defaultValues: {
            username: process.env?.REACT_APP_DEFAULT_LOGIN,
            password: process.env?.REACT_APP_DEFAULT_PASSWORD,
        }
    });
    //const onSubmit = handleSubmit((data) => globals.log(data));



    const onSubmit: SubmitHandler<FormLoginValues> = data => {
        globals.log(data);
        setError("")

        const authdata = {
            username: data.username,
            password: md5(data.password),
            language: i18n.language
        };

        globals.postRequest(config.url + config.login, authdata,
            (data: any) => {
                let api = data.api
                if (process.env?.REACT_APP_URL_PATCH) {
                    api = process.env.REACT_APP_URL_PATCH
                }
                globals.log("new path: " + api)

                globals.postRequest(api + config.login, authdata,
                    (data: any) => {
                        //globals.log(data)

                        setUser({
                            id: 1,
                            url: api,
                            name: data.firstname + ' ' + data.lastname,
                            isLoggedIn: true,
                            token: data.token,
                            company_id: data.company_id,
                        })
                    },
                    (data: any) => {
                        setError(data)
                    },
                )
            },
            (data: any) => {
                setError(data)
            },
        )

        return false;
    }

    globals.log(watch("username")); // watch input value by passing the name of it

    if (user.isLoggedIn) {
        return (
            <Navigate to="/" replace />
        )
    }

    return (
        <div>
                {error && <div><span>{error}</span></div>}

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <div className="group groupicon">
                    <input type="email" placeholder={t("Email")} {...register("username", {
                        required: true,
                        pattern: {
//                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i,
                            message: "invalid email address"
                        }
                    })} />
                        <span><i className="material-icons-outlined"  style={{color: 'rgba(0, 0, 0, 0.35)'}}>email</i></span>
                    </div>
                    {errors?.username && <span>{errors?.username?.message}</span>}
                    {errors?.username && !errors?.username?.message && <span>This field is required</span>}

                    <br/>
                    {/* include validation with required or other standard HTML validation rules */}
                    <div className="group ">
                    <input type="password" placeholder={t('Password')} {...register("password", { required: true })} />
                    {/* errors will return when field validation fails  */}
                        <span><i className="material-icons-outlined">visibility_off</i></span>
                    </div>
                    {errors?.password && <span>This field is required</span>}
                    <br/>

                    <button type="submit"  >{t('Login')}</button>
                    <br/>
                    <br/>
                </form>
        </div>
    )
}

