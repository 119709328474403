import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn, menuIsVisible, networkLoadingStatus} from "../atoms";
import globals from "../globals";
import DialogOk, {DialogError} from "../Components/DialogOk"
import AppHeader from "../Components/AppHeader";
import AppMenu from "../Components/AppMenu";
import GoogleMapLoader from "../Components/GoogleMapLoader";
import MainGoogleMap from "./MainGoogleMap";
import BarcodesLiveMapView from "../Components/BarcodesLiveMapView";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import config from "../config";
import {ObjectTypeSpreadProperty} from "@babel/types";

type AssignData = {
    material_id: string,
    material_number: string,
    material_1: string,
    material_TrayNo: string,
    material_3: string,

    beacon_container: string,
    container_id: string,
    container_number: string,

    esltag: string,
//    anyString: string,
}

const defaultData: AssignData = {
    material_id: "",
    material_number: "",
    material_1: "",
    material_TrayNo: "",
    material_3: "",

    beacon_container: "",
    container_id: "",
    container_number: "",

    esltag: "",
}

export default function BrakeshoeLocations(props: any) {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();
    const [user, setUser] = useRecoilState(userLoggedIn);
//    const resetUserState = useResetRecoilState(userLoggedIn);
    const [isMenuVisible, setMenuVisible] = useRecoilState(menuIsVisible)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);

    const [anyString, setAnyString] = useState("");
    const [searchBarcode, setSearchBarcode] = useState("");
    const [searchBarcodeNow, setSearchBarcodeNow] = useState("");

    // const [barcodeContainer, setContainerBarCode] = useState("00123");
    // const [barcodeBeacon, setBeaconBarCode] = useState("00110");
    // const [barcodeEsl, setEslBarCode] = useState("A0A3B8274E5B2");
    const [dataLots, setDataLots] = useState([] as any);
    const [dataParkingLots, setDataParkingLots] = useState({} as any);

    const [assignData, setAssignData] = useState(defaultData)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenError, setIsOpenError] = useState(false)
    const [szErrorMessage, setErrorMessage] = useState("")

    const clickReset = () => {
        // setEslBarCode("")
        // setBeaconBarCode("")
        // setContainerBarCode("")
        setAssignData({
            material_id: "",
            material_number: "",
            material_1: "",
            material_TrayNo: "",
            material_3: "",
            beacon_container: "",
            container_id: "",
            container_number: "",
            esltag: "",
        })
        setAnyString("")
    }

    const setError = (data: string) => {
        setErrorMessage(data)
        // audio.play()
    }

    const closeDialog = () => {
        setIsOpen(false)
        clickReset()
    }

    const closeDialogError = () => {
        setIsOpenError(false)
    }

    const runSearch = () => {
        console.log('onSearchClick: ' + searchBarcode)
        setSearchBarcodeNow(searchBarcode)
    }

    const runCancelSearch = () => {
        setSearchBarcode('')
        setSearchBarcodeNow('')
    }

    useEffect(()=>{
        let dataNew = { ...dataParkingLots }
        console.log('p data:', dataNew)
        dataLots.forEach((item: any)=>{
            if (!dataNew.hasOwnProperty(item.layout_box)) {
                dataNew[item.layout_box] = []
            }
            let idx = dataNew[item.layout_box].indexOf(item.barcode)
            if (item.action == 'enter') {
                if (idx == -1) {
                    dataNew[item.layout_box].push(item.barcode)
                }
            } else
            if (item.action == 'exit') {
                if (idx > -1) {
                    dataNew[item.layout_box].splice(idx, 1)
                    // delete dataNew[item.layout_box][idx]
                }
            }
        })
        setDataParkingLots(dataNew)
    }, [dataLots])

    useEffect(() => {
        let token = ''
        const userData = localStorage.getItem('userLoggedIn');

        if (userData) {
            let data = JSON.parse(userData)
            if (data && data.token) {
                token = data.token
            }
        }

        const controller = new AbortController();
        const fetchData = async () => {
            await fetchEventSource(user.url + config.urls.geofence_barcode, {
                method: "POST",
                headers: {
                    Accept: "text/event-stream",
                    Authorization: token ? `Bearer ${token}` : '',
                },
                signal: controller.signal,
                onopen(res: Response): Promise<void> {
                    if (res.ok && res.status === 200) {
                        console.log("Connection made ", res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.log("Client side error ", res);
                    }
                    return new Promise<void>((resolve, reject) => {
                        resolve();
                    });
                },
                onmessage(event) {
                    //console.log(event.data);
              //      const parsedData = JSON.parse(event.data);
              //      setData((data) => [...data, parsedData]);
                    const parsedData = JSON.parse(event.data);
                    console.log('current data:', dataParkingLots)
                    console.log('parsedData', parsedData)
                    setDataLots(parsedData)
                },
                onclose() {
                    console.log("Connection closed by the server");
                },
                onerror(err) {
                    console.log("There was an error from server", err);
                },
            });
        };
        fetchData();
        return () => controller.abort();
    }, []);

    return (
            <>
                    <div className="fullw">
                        <h2>Brakeshoes</h2>
                        {
                            Object.keys(dataParkingLots).map((item, idx)=>{
                                let present = dataParkingLots[item].length
                                return (
                                    <div key={idx}>
                                        <h2>{item} total: {present}</h2>
                                        <div>
                                            {
                                                dataParkingLots[item].map((value: any, indx: any)=>{
                                                    return <span key={indx} style={{padding: "10px"}}>
                                                            {value}
                                                    </span>
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div></div>
                    </div>
                    <p>

                    </p>

        </>
    )
}
/*
                    <DialogOk onClose={closeDialog} isOpen={isOpen} autoCloseMS={2000}>
                        <p>Everything fine...<br/>
                            No Errors</p>
                    </DialogOk>
                    <DialogError onClose={closeDialogError} isOpen={isOpenError} >
                        <p>{szErrorMessage}</p>
                    </DialogError>

 */