import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn, menuIsVisible, networkLoadingStatus} from "../atoms";
import globals from "../globals";
import DialogOk, {DialogError} from "../Components/DialogOk"
import AppHeader from "../Components/AppHeader";
import AppMenu from "../Components/AppMenu";

import { Outlet } from 'react-router-dom'



export default function LayoutMain(props: any) {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();
    const [user, setUser] = useRecoilState(userLoggedIn);
    const [isMenuVisible, setMenuVisible] = useRecoilState(menuIsVisible)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenError, setIsOpenError] = useState(false)
    const [szErrorMessage, setErrorMessage] = useState("")

    const closeDialog = () => {
        setIsOpen(false)
    }

    const closeDialogError = () => {
        setIsOpenError(false)
    }

    const onMenuClick = () => {
        setMenuVisible({
            isOpen: !isMenuVisible.isOpen
        })
    }

    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // inputRef?.current?.focus();
    }, [inputRef])

    if (!user.isLoggedIn) {
        return (
            <Navigate to="/login" replace />
        )
    }

    return (
        <div className="App"
             ref={inputRef}
        >
            <div>
                <AppHeader afterClick={onMenuClick} isOpen={isMenuVisible.isOpen} hideMenu={false}/>
                <div className="main mainwindow">
                    <Outlet />

                    <p>

                    </p>

                </div>
            </div>
            <AppMenu isOpen={isMenuVisible.isOpen} afterClick={onMenuClick} />
            <DialogOk onClose={closeDialog} isOpen={isOpen} autoCloseMS={2000}>
                <p>Everything fine...<br/>
                    No Errors</p>
            </DialogOk>
            <DialogError onClose={closeDialogError} isOpen={isOpenError} >
                <p>{szErrorMessage}</p>
            </DialogError>
        </div>
    )
}
