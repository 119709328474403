import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import SuspenseDefaultLoader from './Suspense/SuspenseDefaultLoader'
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import config from "./config";

declare global {
    interface Window {
        cordova: any
//        location: any
        // bluetoothle: any
        // device: any,
        // Camera: any,
        //
        // machines: any,
        // autoactivate: any,
    }
}
declare global {
    interface Navigator {
        splashscreen: any
        // notification: any
        // app: any,
        // camera: any,
    }
}

function startApp () {
    if (!config.debug) {
        console.log = () => {}
    }

    ReactDOM.render(
        <React.StrictMode>
            <RecoilRoot>
                <I18nextProvider i18n={i18n}>
                <Suspense fallback={<SuspenseDefaultLoader/>}>
                    <App/>
                </Suspense>
                </I18nextProvider>
            </RecoilRoot>
        </React.StrictMode>,
        document.getElementById('root')
    );
}


if (!config.debug) {
  console.log = () => {}
}

if (!window.cordova) {
    startApp();
} else {
    document.addEventListener('deviceready', startApp, false);
}

//startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

