import React, {ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import '../fonts/MaterialIconsOutlined-Regular.css'
import '../i18n';


export interface IAppMenuProps {
    isOpen: boolean,
    afterClick: () => void,
}

export default function AppMenu(props: IAppMenuProps) {
//    const [isOpen, setOpen] = useState(false)
    const {t} = useTranslation()

    const classActive = props.isOpen ? " menunow" : "menuhidden"

    const onClick = () => {
        if (props.afterClick) props.afterClick()
    }

    useEffect(()=>{
        if (props.isOpen) {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.width = `100%`;
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [props.isOpen])

    return (
        <div className={classActive}>
            <div className="headerPart" onClick={onClick}>
            </div>
            <div className="menuPart" onClick={onClick}>
                <div className="menuBar">
                    <ul>
                        <MenuItem title={"menu.Dashboard"} url={"/"}  icon={"home"}/>
                        {/*<MenuItem title={"menu.Lease"} url={"/lease"} icon={"help"} />*/}
                        {/*<MenuItem title={"menu.Brakeshoes"} url={"/brakeshoes"} icon={"help"} />*/}
                        {/*<MenuItem title={"menu.Depots"} url={"/depots"} icon={"apps"}/>*/}
                        {/*<MenuItem title={"menu.Layouts"} url={"/layouts"} icon={"help"} />*/}
                        {/*<MenuItem title={"menu.Livemap"} url={"/livemap"} icon={"help"} />*/}
                        {/*<MenuItem title={"menu.Report"} url={"/report"} icon={"help"} />*/}
                        <MenuItem title={"menu.Logout"} url={"/logout"} icon={"logout"} />
                    </ul>
                </div>
            </div>
        </div>
    )
}

interface IMenuItemProps {
    title: string,
    url: string,
    isActive?: boolean,
    icon?: any
}

function MenuItem (props: IMenuItemProps) {
    const {t, i18n} = useTranslation()
    const location = useLocation();
    const pathname = location.pathname;

    // props.isActive
    const classActive = (pathname == props.url) ? "active" : ""
    const [classHover, setClassHover] = useState("")

    const mouseOut = (e: any) => {
        console.log("mouseOut")
        setClassHover("")
    }

    const mouseIn = (e: any) => {
        console.log("mouseIn")
        setClassHover("hover")
    }

    return (
        <li className={classActive + " " + classHover}
        onTouchStart={mouseIn} onTouchEnd={mouseOut} onTouchCancel={mouseOut} onTouchCancelCapture={mouseOut}
        ><Link to={props.url}>
            {
                (props.icon) && (
                    <span><i className="material-icons-outlined">{props.icon}</i></span>
                )

            }
            {
                (!props.icon) && (
                    <span></span>
                )

            }
            {t(props.title)}</Link></li>
    )
}