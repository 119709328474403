import React, {ReactNode, useState} from "react";

export interface IAppHeaderProps {
    isOpen?: boolean,
    hideMenu?: boolean,
    afterClick?: () => void,
}

export default function AppHeader(props: IAppHeaderProps) {
//    const [isOpen, setOpen] = useState(false)

    const classActive = props.isOpen ? " is-active" : ""

    const onClick = () => {
//        const newState = !props.isOpen
//        setOpen(newState)
        if (props.afterClick) props.afterClick()
    }

    return (
        <div className="header">
            {
                !props.hideMenu && (
                    <div className={"hamburger hamburger--boring" + classActive} onClick={onClick}>
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>
                )
            }
            {
                !props.hideMenu && (
                    <div className="vl"></div>
                )
            }
            <div className="appname h3-small-arial-16">
                {process.env.REACT_APP_TITLE}
            </div>
        </div>
    )
}
