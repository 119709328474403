import React, {ReactNode, useEffect, useState} from "react";
import {
    GoogleMap,
    GroundOverlay,
    InfoBox,
    InfoWindow,
    OverlayView,
    Polygon, useGoogleMap,
    useJsApiLoader
} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";

export const getLatLonByDeltaMovement = function(lat: number, lon: number, dx: number, dy: number){
    let earth = 6371137.0 //6378.137,  //radius of the earth in m (was km)
    let pi = Math.PI
    let m = (1 / ((2 * pi / 360) * earth))  //1 meter in degree

    let new_latitude = 1*lat + (dx * m)

    let cos = Math.cos

    let new_longitude = 1*lon + (dy * m) / cos(lat * (pi / 180))
    let p = {lat: new_latitude, lng: new_longitude}
    return p
}

export const getLatLonByBearing = function(lat: number, lon: number, bearing: number, distance: number) {
//        Formula:	φ2 = asin( sin φ1 ⋅ cos δ + cos φ1 ⋅ sin δ ⋅ cos θ )
//		λ2 = λ1 + atan2( sin θ ⋅ sin δ ⋅ cos φ1, cos δ − sin φ1 ⋅ sin φ2 )
//		where	φ is latitude, λ is longitude, θ is the bearing (clockwise from north), δ is the angular distance d/R; d being the distance travelled, R the earth’s radius
//		(all angles in radians)
    lat = lat * Math.PI / 180;
    lon = lon * Math.PI / 180;
    bearing = bearing * Math.PI / 180;
    // console.log('bearing: '+bearing)
    // if (bearing > Math.PI) {
    //     bearing = Math.PI-bearing;
    // } else
    // if (bearing < -Math.PI) {
    //     bearing = Math.PI +bearing
    // }
//            $bearing = deg2rad($bearing);
    const R = 6371137.0;
    let lat2 = Math.asin( Math.sin(lat) * Math.cos(distance/R) +
        Math.cos(lat) * Math.sin(distance/R) * Math.cos(bearing) );
    let lon2 = lon + Math.atan2(Math.sin(bearing) * Math.sin(distance/R) * Math.cos(lat),
        Math.cos(distance/R) - Math.sin(lat) * Math.sin(lat2));


    lat2 = lat2 * 180 / Math.PI
    lon2 = lon2 * 180 / Math.PI
    lon2 = (lon2 + 540) % 360 - 180
    let p = {lat: lat2, lng: lon2}
    // console.log('pos: '+JSON.stringify(p))
    return p
}

const calculateOverlayBounds = (lbMarker_: any, posX: any, posY: any, posXY: any) => {

    console.log("calculateOverlayBounds ")
    let bounds = new google.maps.LatLngBounds()


    const LB = { lat: lbMarker_.lat, lng: lbMarker_.lng }
    const TR = { lat: lbMarker_.lat, lng: lbMarker_.lng }

    LB.lat = Math.min(lbMarker_.lat,
        posX.lat,
        posY.lat,
        posXY.lat
    )
    LB.lng = Math.min(lbMarker_.lng,
        posX.lng,
        posY.lng,
        posXY.lng
    )
    TR.lat = Math.max(lbMarker_.lat,
        posX.lat,
        posY.lat,
        posXY.lat
    )
    TR.lng = Math.max(lbMarker_.lng,
        posX.lng,
        posY.lng,
        posXY.lng
    )

    bounds.extend({ lat: LB.lat, lng: LB.lng })
    bounds.extend({ lat: TR.lat, lng: TR.lng })
    // console.log('CalculateLayoutBounds 1', bounds)
    //
    // bounds = new google.maps.LatLngBounds()
    // bounds.extend(lbMarker_)
    // bounds.extend(posX)
    // bounds.extend(posY)
    // bounds.extend(posXY)
    // console.log('CalculateLayoutBounds 2', bounds)

    return bounds
}

export const getGipotenuza = (point1: any, point2: any) => {
    const a1 = Math.max(point1.x, point2.x) - Math.min(point1.x, point2.x)
    const b1 = Math.max(point1.y, point2.y) - Math.min(point1.y, point2.y)
    const layoutWidth = Math.sqrt(a1*a1 + b1*b1)

    console.log('layoutWidth', layoutWidth)
    console.log('layoutWidth a1', a1)
    console.log('layoutWidth b1', b1)
    return layoutWidth
}

export const calculateLayoutPostions = (map: google.maps.Map, layout: any): any => {
    console.log('dimantions div')
    // using direction and planar size
    const lbMarker_ = {lat: layout.lb.lat, lng: layout.lb.lon}
    const ltMarker_ = {lat: layout.lt.lat, lng: layout.lt.lon}
    const layoutSize_ = (layout.hasOwnProperty('x'))
        ? {x: layout.x, y: layout.y}
        : {x: layout.width, y: layout.height}

    const projection = map.getProjection()
    if (!projection) return false
    console.log('dimensions projection')

    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    var sw = projection.fromLatLngToPoint(new google.maps.LatLng(lbMarker_.lat, lbMarker_.lng))
    var ne = projection.fromLatLngToPoint(new google.maps.LatLng(ltMarker_.lat, ltMarker_.lng))

    if (!sw) return false
    console.log('dimantions projection sw')
    if (!ne) return false
    console.log('dimantions projection ne')

    // Resize the image's div to fit the indicated dimensions.
    var min_x = Math.min(sw.x, ne.x)
    var max_x = Math.max(sw.x, ne.x)
    var min_y = Math.min(sw.y, ne.y)
    var max_y = Math.max(sw.y, ne.y)

    let dx = (max_x - min_x)
    let dy = (max_y - min_y)
    let angle = Math.atan(dx / dy)

    //bearing = angle
    angle = (angle*180/Math.PI + 360) % 360;
    console.log('calc angle: ' + ((angle*180/Math.PI + 360) % 360) + ' or ' + (angle*180/Math.PI + 360))
    //angle = Number.parseInt(angle.toFixed(1))
    if (sw.x < ne.x ) {
        if (ne.y < sw.y) {
            angle = angle + 0
        } else {
            angle = -1*angle +180
        }
    } else {
        if (ne.y < sw.y) {
            angle = -1*angle + 0
        } else {
            angle = angle + 180
        }
    }

    console.log('min/max: ', min_y, max_y)

    // console.log('myAngle: ', myAngle)
    if (layout.hasOwnProperty('angle')) {
        console.log('angle replaced: ' + angle + ' -> ' + layout.angle)
        angle = layout.angle
    }
    let posY = getLatLonByBearing(lbMarker_.lat, lbMarker_.lng, 1*angle, layoutSize_.y/100)
//    setPosYObj(posY)
    let pxY = projection.fromLatLngToPoint(new google.maps.LatLng(
        posY.lat,
        posY.lng
    ));
    if (!pxY) return false

    let h = Math.sqrt((pxY.y - sw.y)*(pxY.y - sw.y) + (pxY.x - sw.x)*(pxY.x - sw.x))
    console.log('h first: '+h)
//        div.style.height = h + 'px';

    let posX = getLatLonByBearing(lbMarker_.lat, lbMarker_.lng, 1*angle+90, layoutSize_.x/100)
//    setPosXObj(posX)
    let pxX = projection.fromLatLngToPoint(new google.maps.LatLng(
        posX.lat,
        posX.lng
    ));
    if (!pxX) return false

    var w = Math.sqrt((pxX.y - sw.y)*(pxX.y - sw.y) + (pxX.x - sw.x)*(pxX.x - sw.x))
    // div.style.width = w + 'px';

    let posXY = getLatLonByBearing(posX.lat, posX.lng, 1*angle, layoutSize_.y/100)
//    setPosXObj(posX)
    let pxXY = projection.fromLatLngToPoint(new google.maps.LatLng(
        posXY.lat,
        posXY.lng
    ));
    if (!pxXY) return false


    const hh = Number.parseInt( h.toFixed(0) )
    console.log('h second: '+h)
    const ww = Number( w.toFixed(0) )
    // div.style.left = sw.x + 'px';
    // div.style.top = sw.y + 'px';
    // div.style.transform = 'rotate(' + angle +'deg)';

    console.log('H: '+h + '  w:' + w + ' left: '+sw.x + ' top: '+sw.y + ' angle: '+angle)

    const overlayBounds = calculateOverlayBounds(lbMarker_, posX, posY, posXY)
    const swPoint = overlayBounds.getSouthWest()
    const nePoint = overlayBounds.getNorthEast()
    const swPointProjection = projection.fromLatLngToPoint(swPoint)
    const nePointProjection = projection.fromLatLngToPoint(nePoint)

    if (!swPointProjection) return
    if (!nePointProjection) return

    let geoPixelData = {
        mapWidth: Math.max(swPointProjection.x, nePointProjection.x) - Math.min(swPointProjection.x, nePointProjection.x),
        mapHeight: Math.max(swPointProjection.y, nePointProjection.y) - Math.min(swPointProjection.y, nePointProjection.y),
        mapLngLeft: swPoint.lng(),
        mapLngRight: nePoint.lng(),
        mapLatBottom: swPoint.lat(),

        pLB: sw,
        pX: pxX,
        pY: pxY,
        pXY: pxXY,

        pZZ: {
            x: Math.min(swPointProjection.x, nePointProjection.x),
            y: Math.min(swPointProjection.y, nePointProjection.y),
        }
    }

    return {
        height_full: h,
        width_full: w,
        height: hh + 'px',
        width: ww + 'px',

        left: sw.x + 'px',
        top: sw.y + 'px',
        transform: 'rotate(' + angle +'deg)',
        angle: angle,

        pLB: sw,
        pX: pxX,
        pY: pxY,
        pXY: pxXY,

        posX: posX,
        posY: posY,
        posXY: posXY,
        lbMarker_: lbMarker_,
        ltMarker_: ltMarker_,
        layoutSize_: layoutSize_,
        overlayBounds: overlayBounds,

        geoPixelData: geoPixelData,
    }
    // setStyleDiv({
    //     ...styleDiv,
    //     height: h + 'px',
    //     width: w + 'px',
    //
    //     left: sw.x + 'px',
    //     top: sw.y + 'px',
    //     transform: 'rotate(' + angle +'deg)',
    // })
}


