import React, {ReactNode, useEffect, useState} from "react";
import {GoogleMap, InfoBox, InfoWindow, useJsApiLoader} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {forEach} from "@react-google-maps/api/dist/utils/foreach";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import globals from "../globals";
import {useRecoilState} from "recoil";
import {userLoggedIn} from "../atoms";
import {string} from "prop-types";
import {calculateLayoutPostions, getLatLonByBearing, getLatLonByDeltaMovement} from "../Libs/CalculateLeyoutPositions";
import LayoutPictureOverlay from "./LayoutPictureOverlay";
import config from "../config";


export interface IAdjustLayoutMapViewProps {
    layoutData: any,
}

interface IAnyReactComponentProps {
    key?: number,
    text: string,
    position: google.maps.LatLng,
    onClick?: () => void,
}

interface IMarkerProps {
    id: number,
    text: string,
    title?: string,
    lat: number,
    lng: number,
    icon?: any,
    info?: boolean,
}

const defaultMarkers: IMarkerProps[] = []

const AnyReactComponent = (props: IAnyReactComponentProps) => <div onClick={props.onClick} className="markerDot">{props.text}!</div>;

export default function AdjustLayoutMapView(props: IAdjustLayoutMapViewProps) {

    const [user] = useRecoilState(userLoggedIn);

    const [myMarkers, setMyMarkers] = useState(defaultMarkers)
    const [intervalTime, setIntervalTime] = useState(0 as number)

    const [firstTime, setFirstTime] = useState(true as boolean)
    const [fixedBound, setFixedBound] = useState(null as any)

    const [activeMarker, setActiveMarker] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    // const center = {
    //     // lat: 52.473380,
    //     // lng: 13.456899,
    //     lat: 52.05601,
    //     lng: 5.106109,
    // }

    // console.log('BarcodesLiveMapView ' + props.search)

    const [timeCounter, setTimeCounter] = useState(999 as number)
    const [requestReady, setRequestReady] = useState(true as boolean)

    const [overlay, setOverlay] = useState({} as any)
    const [layoutData, setLayoutData] = useState({} as any)

    // useEffect(()=>{
    //     const id = setTimeout(()=>{
    //         // console.log('every 1s')
    //
    //         // console.log('every 1s props.search ' + props.search )
    //         if (props.search != '') {
    //             console.log('every 000s props.search ' + props.search )
    //             setTimeCounter(timeCounter +1)
    //         } else
    //         if (timeCounter >= a-1) {
    //             let d2 = new Date()
    //             let dd = d2.toISOString().substr(0, 19)
    //             const ddd = dd.replace('T', ' ')
    //             // console.log('run now: ' + ddd)
    //             setTimeCounter(0)
    //             runGetBusLocations(ddd)
    //         } else {
    //             setTimeCounter(timeCounter +1)
    //         }
    //     }, 1000)
    //
    //     return () => {
    //         clearInterval(id)
    //     }
    // }, [timeCounter])

    const a = 5

    // const runGetBusLocations = (ddd: string) => {
    //     console.log('date: '  + ddd)
    //     if (requestReady) {
    //         setRequestReady(false)
    //         getCurrentPositions(ddd)
    //     }
    // }


//     const getCurrentPositions = (d: string) => {
// //        let d = "2022-08-28 14:00:43"
//         let formData = {
//             when: d,
//         } as any;
//
//         let token = ''
//         const userData = localStorage.getItem('userLoggedIn');
//
//         if (userData) {
//             let data = JSON.parse(userData)
//             if (data && data.token) {
//                 token = data.token
//                 formData = {
//                     auth_key: token,
//                 }
//             }
//         }
//
//         globals.postRequest(user.url + "/apiv3_geo/get_barcode_locations", formData,
//             (response: any) => {
//                 let tabledata = response;
//                 // console.log('tabledata:', tabledata)
//                 // console.log('devices:', tabledata.locations)
//
//                 let markers = tabledata.locations
//                     .filter((device: any) => {
//                         let d1 = Date.parse(d)
//                         let d2 = Math.round(device.timestamp)
//                         // console.log('d2 ' + device.timestamp)
//                         // console.log('d2 ' + d2)
//                         // console.log(device.barcode + ': ' + device.timestamp + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
//                         return ((d1-d2) < 100*24*60*60*1000) // *24
//                     })
//                     .map((device: any) => {
//                             let d2222 = new Date(device.timestamp)
//                             let d22 = (new Date(d2222)).toLocaleTimeString()
// //                        console.log(device.device + ': ' + device.when )
//                         let color = "yellow"
//                         let d1 = Date.parse(d)
//                         let d2 = Math.round(device.timestamp) //new Date(device.timestamp)
//                         if (d1-d2 > 60*60*1000) {
//                             // old...
//                             color = "red"
//                             d22 = (new Date(d2222)).toLocaleString()
//                         }
//                             //console.log(device.device + ': ' + device.when + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
// //                            return ((d1-d2) < 24*) // *24
//
//                         return {
//                             id: device.barcode,
//                             title: device.barcode, //device.when, //"4102",
//                             text: device.barcode + "\n" + d22, //device.when, //"4102",
//                             lat: 1*device.lat,
//                             lng: 1*device.lng,
//                             icon: {
//                                 path: google.maps.SymbolPath.CIRCLE,
//                                 scale: 7,
//                                 fillColor: color,
//                                 fillOpacity: 0.9,
//                                 strokeColor: "gold",
//                                 strokeWeight: 2,
//                             },
//                             info: true,
//                         }
//                     }
//                 )
//                 setMyMarkers(markers)
//                 setRequestReady(true)
//             },
//             (message: any) => {
//                 globals.log('Error:', message)
//                 // setError(message)
//                 // setIsOpenError(true)
//                 setRequestReady(true)
//             })
//     }

//     const runSearch = (value: string) => {
//         let formData = {
//             barcode: value,
//             limit: 1,
//         } as any;
//
//         globals.postRequest(user.url + "/apiv3_geo/get_barcode_barcode_locations", formData,
//             (response: any) => {
//                 let tabledata = response;
//                 console.log('get_barcode_locations_barcode:', tabledata)
//
//                 let markers = tabledata.locations
//                     .filter((device: any) => {
//                         return true
//                     })
//                     .map((device: any) => {
//                             let d2222 = new Date(device.timestamp)
//                             let d22 = (new Date(d2222)).toLocaleTimeString()
// //                        console.log(device.device + ': ' + device.when )
//                             let color = "yellow"
//                             //console.log(device.device + ': ' + device.when + '<' + d + ' : ' + d1 + '-' + d2 + '=' + (d1-d2) )
// //                            return ((d1-d2) < 24*) // *24
//
//                             let barcode = props.search
//                             return {
//                                 id: barcode,
//                                 title: barcode, //device.when, //"4102",
//                                 text: barcode + "\n" + (new Date(device.timestamp)).toLocaleString(), //device.when, //"4102",
//                                 lat: 1*device.lat,
//                                 lng: 1*device.lng,
//                                 icon: {
//                                     path: google.maps.SymbolPath.CIRCLE,
//                                     scale: 7,
//                                     fillColor: color,
//                                     fillOpacity: 0.9,
//                                     strokeColor: "gold",
//                                     strokeWeight: 2,
//                                 },
//                                 info: true,
//                             }
//                         }
//                     )
//                 setFirstTime(true)
//                 setMyMarkers(markers)
//                 setRequestReady(true)
//             },
//             (message: any) => {
//                 globals.log('Error:', message)
//                 // setError(message)
//                 // setIsOpenError(true)
//                 setRequestReady(true)
//             })
//     }


    const [map, setMap] = React.useState(null)

    const calculateLayoutData = (rowData: any) => {
        let row = JSON.parse(JSON.stringify(rowData))

        let layout = {
            layout_photo: row.picture,
        }

        console.log('rowrowData', rowData)
        console.log('rowrowrow', row)

        if (row.hasOwnProperty('dx') && row.hasOwnProperty('dy')) {
            let lbMarker_ = {lat: row.lb.lat, lng: row.lb.lon}
            let ltMarker_ = {lat: row.lt.lat, lng: row.lt.lon}
            lbMarker_ = getLatLonByDeltaMovement(row.lb.lat, row.lb.lon, row.dx, row.dy)
            ltMarker_ = getLatLonByBearing(lbMarker_.lat, lbMarker_.lng, row.angle, 1000)
            row.lb.lat = lbMarker_.lat
            row.lb.lon = lbMarker_.lng
            row.lt.lat = ltMarker_.lat
            row.lt.lon = ltMarker_.lng
        }
        console.log('rowAFTER', row)

        let markers = calculateLayoutPostions(map as unknown as google.maps.Map, row)
        let ll = {
            layout: layout,
            markers: markers,
        }
        console.log('ll', ll)
        setOverlay(ll)
    }

    useEffect(()=>{
        if (layoutData.hasOwnProperty('picture')) {
            calculateLayoutData(layoutData)
        }
    }, [layoutData])

    // load maps
    useEffect(()=>{
        if (!map) return
        let formData = {} as any
        globals.postRequest(user.url + config.urls.maps, formData,
            (response: any) => {
                let tabledata = response;
                // console.log('load maps:', tabledata)


                if (tabledata.hasOwnProperty('maps')) {
//                    console.log(tabledata.maps)
                    tabledata.maps.forEach((row: any)=>{
                        console.log('map row:', row)
                        if (21 == row.id) {
                            setLayoutData(row)
                            // calculateLayoutData(row)
                            // let layout = {
                            //     layout_photo: row.picture,
                            // }
                            // let markers = calculateLayoutPostions(map as unknown as google.maps.Map, row)
                            // return {
                            //     layout: layout,
                            //     markers: markers,
                            // }
                            //break;
                        }
                    })
                    // let d = tabledata.maps.map((row: any)=>{
                    //
                    //         let layout = {
                    //             layout_photo: row.picture,
                    //         }
                    //         let markers = calculateLayoutPostions(map as unknown as google.maps.Map, row)
                    //         return {
                    //             layout: layout,
                    //             markers: markers,
                    //         }
                    // })
                    // setOverlays(d)
                }
                // setMyMarkers(markers)
                // setRequestReady(true)
            },
            (message: any) => {
                globals.log('Error:', message)
            })
    }, [map])

    const fitBounds = (map: any) => {
        if (map != null && myMarkers != null && myMarkers.length > 0 && firstTime) {
            setFirstTime(false)
            let bounds = new google.maps.LatLngBounds()
            // myMarkers.forEach((value) => {
            //     bounds.extend({lat: value.lat, lng: value.lng})
            // })
            for (let i=0;i<myMarkers.length;i++) {
                const value = myMarkers[i]
                // console.log('item: ', value)
                bounds.extend({lat: value.lat, lng: value.lng})
            }
//            (map as any).fitBounds(bounds);
        }
    }

    const onLoad = React.useCallback(function callback(map) {
        // console.log('onLoad: ', myMarkers)
        setMap(map)
        setFirstTime(true)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    // const onMarkerLoad = (marker: any) => {
    //     // console.log('marker: ', marker)
    // }

    useEffect(()=>{
        fitBounds(map)
        if (fixedBound) {
        //    (map as any).fitBounds(fixedBound);
        }
    }, [myMarkers])

    useEffect(()=>{
        fitBounds(map)
    }, [map])

    // useEffect(()=>{
    //     if (props.search != '') {
    //         runSearch(props.search)
    //     } else {
    //         setFirstTime(true)
    //     }
    // }, [props.search])

    // const onMyClick = (marker: IMarkerProps) => () => {
    //     console.log('onMyClick marker', marker)
    //
    //     let points = myMarkers.filter((item) => {
    //         return (item.id != marker.id)
    //     })
    //     marker.info = !marker.info
    //     points.push(marker)
    //     setMyMarkers(points)
    //     // handleActiveMarker(marker.id)
    // }

    const onZoomChanged = () => {
        // console.log('set bounds after zoom')
        if (map) {
            const m = map as unknown as google.maps.Map
            const bounds = m.getBounds()
            setFixedBound(bounds)
        }
    }

    /*
                {
                (layoutPicture) && (layoutPictureMarkers) && (
                    <LayoutPictureOverlay layout={layoutPicture}
                                          markers={layoutPictureMarkers}
                                          busMarkers={busMarkers}
                                          overlayBounds={overlayBounds}
                                          onLayoutClick={onLayoutClick}
                    />
                )
            }

     */


    useEffect(()=>{
        console.log('layoutData ', layoutData)
        if (!layoutData.hasOwnProperty('id')) return
        let ll = {...layoutData}


        if (props.layoutData.hasOwnProperty('lbLat') && props.layoutData.lbLat) {
            ll.lb.lat = props.layoutData.lbLat
        }
        if (props.layoutData.hasOwnProperty('lbLon') && props.layoutData.lbLon) {
            ll.lb.lon = props.layoutData.lbLon
        }
        // if (props.layoutData.hasOwnProperty('ltLat') && props.layoutData.ltLat) {
        //     ll.lt.lat = props.layoutData.ltLat
        // }
        // if (props.layoutData.hasOwnProperty('ltLon') && props.layoutData.ltLon) {
        //     ll.lt.lon = props.layoutData.ltLon
        // }
        if (props.layoutData.hasOwnProperty('height') && props.layoutData.height) {
            ll.height = props.layoutData.height
        }
        if (props.layoutData.hasOwnProperty('width') && props.layoutData.width) {
            ll.width = props.layoutData.width
        }
        if (props.layoutData.angle) {
            ll.angle = props.layoutData.angle
        }
        if (props.layoutData.dx) {
            ll.dx = props.layoutData.dx
        }
        if (props.layoutData.dy) {
            ll.dy = props.layoutData.dy
        }

        console.log('ll lat', ll)
        setLayoutData(ll)
    }, [props.layoutData])
    // useEffect(()=>{
    //     if (lbLat) {
    //         let ll = {...layoutData}
    //         ll.lb.lat = lbLat
    //         console.log('ll lat', ll)
    //         setLayoutData(ll)
    //     }
    // }, [lbLat])
    // useEffect(()=>{
    //     if (lbLon) {
    //         let ll = {...layoutData}
    //         ll.lb.lon = lbLon
    //         console.log('ll lon', ll)
    //         setLayoutData(ll)
    //     }
    // }, [lbLon])
    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            // center={center}
            // zoom={5}
            zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onZoomChanged}
        >
            {
                (overlay.hasOwnProperty('layout')) && (
                <LayoutPictureOverlay layout={overlay.layout}
                    markers={overlay.markers}
                />
                )
            }
        </GoogleMap>
    )
}

/*
            {myMarkers
                .filter((marker)=>{
                    const m = map as unknown as google.maps.Map
                    const b = m.getBounds()
                    if (b) {
                        const ne = b.getNorthEast()
                        const sw = b.getSouthWest()
                        if (ne && sw) {
                            return (
                                sw.lat() < marker.lat && marker.lat < ne.lat() &&
                                sw.lng() < marker.lng && marker.lng < ne.lng()
                            )
                        }
                    }
                    return true
                })
                .map((marker) => {
                const pos = {
                    lat: marker.lat,
                    lng: marker.lng,
                }


                const mClick = () => {
                    console.log('item', marker)
                }

                return (<Marker
                    key={marker.id}
                    onLoad={onMarkerLoad}
                    position={pos}
                    icon={marker.icon}
                    // onClick={mClick}
                    onClick={onMyClick(marker)}
                    title={marker.text}
                >
                    {
                        (marker.info) && (
                            <InfoWindow
                                position={pos}
                                onCloseClick={onMyClick(marker)}
                                options={{
                                    disableAutoPan: true
                                }}
                            >
                                <div>{marker.title}</div>
                            </InfoWindow>
                        )
                    }
                </Marker>)

            })}

        <div>
        </div>

 */
