
const config = {
    url: process.env.REACT_APP_SINTRA_MAIN, //"https://sintra-main.sinfosy.com",
    //url1: "https://sul.sinfosy.com/api/",
    //url2: "https://sul-in.sinfosy.com/api/",
    // url: "http://10.10.10.15/",
    // url1: "http://10.10.10.15/",
    // url2: "http://10.10.10.15/",

    login: "/apiv3/login",
    urls: {
        layouts: "/apiv3/layouts",
        device_list_portable: "/apiv3/device_list_portable",
        container_information: "/apiv3/container_information",

        maps: "/apiv3_geo/maps",
        map_layout: "/apiv3_geo/map_layout/", // + layout_id
        get_barcode_locations: "/apiv3_geo/get_barcode_locations",
        get_barcode_barcode_locations: "/apiv3_geo/get_barcode_barcode_locations",
        locations: "/apiv3_geo/locations",

        get_brakeshoe_locations: "/apiv3_geo/get_brakeshoe_locations",

        geofence_barcode: "/apiv3_stream/geofence_barcode",
    },

    // prefix 0x05 when scanned or space for debug
    validContainerRangeFrom: 1,
    validContainerRangeTo: 99999,
    // our regular beacon === container_number
    validBeaconRangeFrom: 1,
    validBeaconRangeTo: 40000,

    debug: (process.env.REACT_APP_DEBUGENABLED == '1')
}

export const testLayoutData = [
    {
        device: "4204",
        device_id: "1408",
        lat: 52.05581,
        lng: 5.105251,
    },
    {
        device: "4209",
        device_id: "1412",
        lat: "52.055767",
        lng: "5.105218",
    },
    {
        device: "4216",
        device_id: "1409",
        lat: "52.055767",
        lng: "5.105207",
    },
    {
        device: "4217",
        device_id: "1403",
        lat: "52.05541",
        lng: "5.10658",
    },
    {
        device: "4207 XC5",
        device_id: "1418",
        lat: "52.055505",
        lng: "5.106224",
    },
    {
        device: "4210",
        device_id: "1414",
        lat: "52.055399",
        lng: "5.106603",
    },
    {
        device: "4203",
        device_id: "1406",
        lat: "52.055709",
        lng: "5.105583",
    },
    {
        device: "4206",
        device_id: "1405",
        lat: "52.055676",
        lng: "5.105572",
    },
    {
        device: "4212",
        device_id: "1417",
        lat: "52.055566",
        lng: "5.10554",
    },
    {
        device: "4213",
        device_id: "1415",
        lat: "52.055668",
        lng: "5.105544",
    },
    {
        device: "4211",
        device_id: "1416",
        lat: "52.055514",
        lng: "5.106259",
    },
    {
        device: "4214",
        device_id: "1413",
        lat: "52.055606",
        lng: "5.105912",
    },
    {
        device: "4208",
        device_id: "1411",
        lat: "52.114082",
        lng: "5.033619",
    },
    {
        device: "4215",
        device_id: "1410",
        lat: "52.05559",
        lng: "5.105905",
    }
]

console.log('process.env.DEBUG: ', process.env.REACT_APP_DEBUGENABLED)
export default config
